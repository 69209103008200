




































import { defineComponent, computed, ref } from '@vue/composition-api'

import RecipientListPreview from '@/components/Communication/RecipientListPreview.vue'
import MessageFilterModal from '@/views/Programs/Communications/vue/Filters/MessageFilterModal.vue'
import InputLabel from '@/elements/InputLabel.vue'
import Loading from '@/elements/Loading.vue'
import ToClipboardBtn from '@/elements/ToClipboardBtn.vue'

import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'

import store from '@/store'
import { MessageFilters, getEmptyMessageFilters } from '@/views/Programs/Communications/model/MessageFilters'
import { useIsRunningChallenge } from '@/lib/common/programTypeHelpers'

export default defineComponent({
  name: 'SmsPreviewer',
  data() {
    return {
      MessageChannelTypes,
    }
  },
  components: { RecipientListPreview, InputLabel, Loading, ToClipboardBtn, MessageFilterModal },

  setup() {
    const isRunningChallenge = useIsRunningChallenge()

    const showFilters = ref(false)
    const filters = ref<MessageFilters>(getEmptyMessageFilters())

    function filterClick() {
      showFilters.value = true
    }

    const count = computed(() => {
      const toCount = [...store.getters.communications.messagePreview.to].length
      return toCount + additionalRecipients.value.length
    })

    const additionalRecipients = computed(() => {
      const delimitedString = store.getters.communications.messagePreview.toDelimited
      if (!delimitedString) return []
      return delimitedString.split(',').filter((recipient) => recipient.trim() !== '')
    })

    const clipBoardRecipients = computed(() => {
      return [...store.getters.communications.messagePreview.to, ...additionalRecipients.value]
    })

    const messagePreviewLoading = computed(() => store.getters.communications.messagePreviewLoading)

    const messageBody = computed({
      get() {
        return store.getters.communications.messagePreview.messageBody
      },
      set(messageBody: string) {
        store.commit.communications.setMessageBody({
          messageBody,
        })
      },
    })

    const smsAssignedNumber = computed(() => store.getters.communications.currentItem.smsAssignedNumber)

    const normalizePhone = (phone: string) => {
      if (!phone) return phone
      if (phone.includes('(')) return phone
      let cleaned = phone.charAt(0) === '+' ? phone.substring(1) : phone
      cleaned = cleaned.charAt(0) === '1' ? cleaned.substring(1) : cleaned
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)!
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return {
      count,
      clipBoardRecipients,
      showFilters,
      filters,
      normalizePhone,
      messageBody,
      messagePreviewLoading,
      filterClick,
      isRunningChallenge,
      smsAssignedNumber,
    }
  },
})
