
















import { defineComponent, ref } from '@vue/composition-api'

import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import SmsRecipientInput from '@/views/Programs/Communications/vue/SmsRecipientInput.vue'
import SmsMessageInput from '@/views/Programs/Communications/vue/SmsMessageInput.vue'
import InputlessStringValidator from '@/elements/InputlessStringValidator.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { messageLogic } from '@/views/Programs/Communications/ext/MessageLogic'

export default defineComponent({
  name: 'SmsComposer',
  props: {},
  components: {
    VeeValidateForm,
    SmsRecipientInput,
    InputlessStringValidator,
    InputLabel,
    SmsMessageInput,
  },
  setup() {
    const form = ref<any | null>(null)
    const { hasRecipients } = messageLogic()

    async function triggerValidation() {
      return await form.value.reportValidity()
    }

    return {
      form,
      hasRecipients,
      triggerValidation,
    }
  },
})
