
























































import { defineComponent, ref, computed, onUnmounted, onMounted } from '@vue/composition-api'

import Loading from '@/elements/Loading.vue'
import SmsComposer from '@/views/Programs/Communications/vue/SmsComposer.vue'
import SmsPreviewer from '@/views/Programs/Communications/vue/SmsPreviewer.vue'
import MessageConfirmationModal from '@/views/Programs/Communications/vue/MessageConfirmationModal.vue'
import CommunicationWarning from '@/views/Programs/Communications/vue/CommunicationWarning.vue'
import dayjs from 'dayjs'
import { messageLogic } from '@/views/Programs/Communications/ext/MessageLogic'
import store from '@/store'
import { mixin as clickaway } from 'vue-clickaway'
import DatePicker from '@/components/ModalDateTimePicker.vue'

export default defineComponent({
  name: 'Sms',
  props: {},
  components: {
    SmsComposer,
    SmsPreviewer,
    MessageConfirmationModal,
    CommunicationWarning,
    DatePicker,
    Loading,
  },
  mixins: [clickaway],
  setup(props, ctx) {
    const logic = messageLogic()
    const smsComposer = ref<any | null>(null)

    // Add new functions for date picker handling
    const handleDatePicked = async (datePicked: Date) => {
      logic.sendCommunicationOn.value = datePicked
      logic.showScheduleSendModal.value = false
      await sendSms()
    }

    const handleDateCancelled = () => {
      logic.showScheduleSendModal.value = false
      logic.sendCommunicationOn.value = null
    }

    const hasCredits = computed(() => {
      return (
        logic.providerInfo.value.smsMaxCredits < 0 ||
        logic.providerInfo.value.smsCredits < logic.providerInfo.value.smsMaxCredits
      )
    })

    const isAllowed = computed(() => {
      return logic.providerInfo.value.isSMSAllowed
    })

    const isTooLate = computed(() => {
      if (logic.isRunningChallenge.value) {
        return isAfterRunningProgram.value
      } else {
        return isAfterSeasonEndDate.value
      }
      return true
    })

    const isAfterSeasonEndDate = computed(() => {
      if (logic.seasonEndDate.value) {
        return dayjs().diff(logic.seasonEndDate.value, 'month', true) < 12
      }
      return false
    })

    const isAfterRunningProgram = computed(() => {
      //Is running program start date more than 14 weeks from today
      const startDt = store.getters.upwardRunning.currentChallenge.startDate
      if (startDt) {
        return dayjs().diff(startDt, 'week', true) < 14
      }
      return true
    })

    async function sendSms() {
      const isValid = await smsComposer.value.triggerValidation()
      if (isValid) {
        await logic.send('sendsms')
      }
    }
    async function scheduleSend() {
      logic.showScheduleSendModal.value = (await smsComposer.value.triggerValidation()) as boolean
    }

    function loadTeams() {
      return new Promise<void>((resolve) => {
        ctx.root.$nextTick(async () => {
          try {
            logic.allTeams.value = await store.dispatch.teams.fetchTeams({
              upwId: logic.upwardLeagueID.value ?? '',
            })
          } finally {
            resolve()
          }
        })
      })
    }

    onMounted(async () => {
      await loadTeams()
    })
    onUnmounted(() => store.commit.communications.clearMessageInformation())

    return {
      smsComposer, //ref to child component
      sendSms,
      isAllowed,
      hasCredits,
      isTooLate,
      scheduleSend,
      ...logic,
      handleDatePicked,
      handleDateCancelled,
      isAfterSeasonEndDate,
    }
  },
})
