




























import { defineComponent, computed, ref, watch } from '@vue/composition-api'

import GospelVideoSelector from '@/components/Communication/GospelViedoSelector/GospelVideoSelector.vue'
import MemoInput from '@/elements/MemoInput.vue'
import store from '@/store'

import Count from '@/lib/support/utilities/smsCount.ts'
import { GospelVideoOptions } from '@/components/Communication/GospelViedoSelector/GospelVideoSelectorData'
import { useIsRunningChallenge } from '@/lib/common/programTypeHelpers'

export default defineComponent({
  name: 'SmsMessageInput',
  components: { GospelVideoSelector, MemoInput },
  setup() {
    const isRunningChallenge = useIsRunningChallenge()
    const message = computed({
      get() {
        return store.getters.communications.messagePreview.messageBody
      },
      set(messageBody: string) {
        store.commit.communications.setMessageBody({ messageBody })
      },
    })

    const showVideo = ref(false)
    function toggle() {
      showVideo.value = !showVideo.value
    }

    const charactersRemaining = computed(() => {
      return Count(message.value)
    })

    const defaultVideoId = computed(() => {
      const noneId = GospelVideoOptions.get('None')?.id
      return noneId ?? ''
    })
    const videoId = ref(defaultVideoId.value)

    const maxPages = 3
    const maxCharacterCount = computed(() => {
      const c = Count(message.value)

      if (message.value) {
        return c.perMessage * maxPages
      }
      return c.perMultiMessage * maxPages
    })

    const maxCharactersReached = computed(() => {
      return (
        charactersRemaining.value.messages > maxPages ||
        (charactersRemaining.value.messages === maxPages && charactersRemaining.value.remaining === 0)
      )
    })

    const readOnly = ref(false)
    watch(
      () => videoId.value,
      () => {
        if (videoId.value != 'None') {
          const option = GospelVideoOptions.get(videoId.value)
          message.value = `${option?.smsText}    ${option?.url}`
          readOnly.value = true
        } else {
          message.value = ''
          readOnly.value = false
        }
      }
    )
    return {
      message,
      charactersRemaining,
      maxCharacterCount,
      maxCharactersReached,
      videoId,
      showVideo,
      toggle,
      readOnly,
      isRunningChallenge,
    }
  },
})
